import ImgSrcset from "../../../../content/posts/image-frontend/ImgSrcset";
import PictureExample from "../../../../content/posts/image-frontend/Picture";
import ReactImage from "../../../../content/posts/image-frontend/ReactImage";
import ReactImageLqip from "../../../../content/posts/image-frontend/ReactImageLqip";
import ReactImageLqipBlur from "../../../../content/posts/image-frontend/ReactImageLqipBlur";
import ImageSlider from "../../../../content/posts/image-frontend/ImageSlider";
import * as React from 'react';
export default {
  ImgSrcset,
  PictureExample,
  ReactImage,
  ReactImageLqip,
  ReactImageLqipBlur,
  ImageSlider,
  React
};