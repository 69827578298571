module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Malte","short_name":"Malte","description":"Website & Portfolio of Malte Janßen, Web Developer and Student.","orientation":"portrait","start_url":"/","background_color":"#000000","theme_color":"#000000","lang":"de-DE","display":"standalone","icon":"static/favicon.svg","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"commonmark":true,"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-images","id":"6f4a2232-f0ef-55a9-a4ea-5eb0f4ad38df","name":"gatsby-remark-images","version":"6.4.0","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":720,"quality":80,"withWebp":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-prismjs","id":"b04498d2-0aaf-5ce5-be65-14bec212441a","name":"gatsby-remark-prismjs","version":"6.19.0","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"noInlineHighlight":false},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-copy-linked-files","id":"0f1d2839-d7bb-56ff-8fee-e91c81f2272d","name":"gatsby-remark-copy-linked-files","version":"5.25.0","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[],"ignoreFileExtensions":["png","jpg","jpeg","bmp","tiff"]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":720,"quality":80,"withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
